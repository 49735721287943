<template>
  <modal
    :title="title"
    :show="show"
    :width="650"
    @close="$emit('close')"
    :is-loading="loading"
  >
    <v-form
      ref="form"
      lazy-validation
      class="d-flex flex-column"
      @submit.prevent="store"
    >
      <div class="form-group">
        <label class="text-caption font-weight-bold"> Name </label>
        <v-text-field
          single-line
          dense
          hide-details="auto"
          :rules="$rules('Name', 'required')"
          v-model="form.name"
          placeholder="Ketik disini"
        />
      </div>
      <div class="form-group">
        <label class="text-caption font-weight-bold"> Urutan </label>
        <v-text-field
          single-line
          dense
          hide-details="auto"
          :rules="$rules('Urutan', 'required|numeric')"
          v-model.number="form.order"
          placeholder="0"
        />
      </div>
      <div class="form-group">
        <label class="text-caption font-weight-bold"> Status </label>
        <v-select
          single-line
          dense
          hide-details="auto"
          :rules="$rules('Status', 'required')"
          v-model="form.record_status"
          placeholder="Pilih salah satu"
          :items="$helper.recordStatusList()"
        />
      </div>
    </v-form>
    <template slot="action">
      <v-btn color="secondary" class="mr-3" @click="$emit('close')" outlined>
        Batal
      </v-btn>
      <v-btn color="secondary" @click="store"> Simpan </v-btn>
    </template>
  </modal>
</template>

<script>
export default {
  name: "ProductCategoryForm",
  components: {
    Modal: () =>
      import(/* webpackChunkName: 'modal' */ "@/components/atom/Modal")
  },
  props: {
    isCreate: {
      default: true
    },
    show: {
      default: false
    },
    title: {
      default: ""
    },
    data: {
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    loading: false,
    form: {
      id: undefined,
      name: "",
      record_status: "",
      order: 0
    }
  }),
  watch: {
    data: function (val) {
      this.form = { ...val };

      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      });
    },
    show: function (val) {
      if (val) this.init();
    }
  },
  methods: {
    init: async function () {
      // load data process, if needed
    },
    store: async function () {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url = `/product-categories/${this.form.id}`;
        let method = "put";
        if (this.isCreate) {
          url = "/product-categories";
          method = "post";
        }
        try {
          let {
            data: { success, message }
          } = await this.$http[method](url, {
            ...this.form,
            order: this.form.order ?? 0
          });
          if (success) {
            this.$dialog.notify.info("Data berhasil disimpan", {
              position: "top-right",
              timeout: 3000
            });
            this.$emit("onSuccess");
            this.close();
          } else
            this.$dialog.notify.error(message, {
              position: "top-right",
              timeout: 3000
            });
        } catch (error) {
          // console.log("err", error);
        } finally {
          this.loading = false;
        }
      }
    },
    close: function () {
      this.$emit("close");
    }
  }
};
</script>
