<template>
  <content-wrapper>
    <template slot="top-of-table">
      <v-text-field
        prepend-inner-icon="mdi-magnify"
        label="Cari"
        hide-details
        outlined
        clearable
        dense
        class="col col-lg-4 col-md-4 col-sm-4"
        v-model="search"
      />
      <v-spacer />
      <v-btn
        class="mr-3 font-weight-bold"
        color="primary"
        @click="showForm(true)"
      >
        Tambah Data
      </v-btn>
    </template>
    <v-data-table
      slot="data-table"
      :headers="headers"
      :items="items"
      :footer-props="$helper.tableFooterConfig"
      :items-per-page="10"
      :server-items-length="dataCount"
      :options.sync="options"
      :loading="isPageLoading"
      :page.sync="currentPage"
      class="elevation-1"
      fixed-header
    >
      <template v-slot:[`item.order`]="{ item }">
        <template v-if="!isPageLoading">
          <div class="d-flex flex-column">
            <v-btn
              v-if="item.order > 0"
              color="accent"
              icon
              rounded
              small
              :loading="isPageLoading"
              @click="moveUp(item)"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn
              v-if="item.order < dataCount - 1"
              color="accent"
              icon
              rounded
              small
              :loading="isPageLoading"
              @click="moveDown(item)"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </template>
      </template>
      <template v-slot:[`item.Action`]="{ item }">
        <action-buttons
          @edit="showForm(false, item)"
          @delete="deleteData(item)"
        />
      </template>
    </v-data-table>
    <form-page
      slot="form"
      :show="show"
      @close="onClose()"
      :title="formTitle"
      :data="selectedData"
      @onSuccess="getData()"
      :is-create="isCreate"
    />
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/organism/Content";
import FormPage from "./form.vue";
import ActionButtons from "@/components/atom/grid/action-buttons";

export default {
  name: "ProductCategory",
  data: () => ({
    selectedData: {},
    show: false,
    formTitle: "",
    isPageLoading: false,
    headers: [
      { text: "Urutan", value: "order", sortable: false },
      { text: "Ketegori", value: "name", sortable: false },
      { text: "Status", value: "record_status", sortable: false },
      { text: "Aksi", value: "Action", sortable: false, width: "100px" }
    ],
    isCreate: true,
    items: [],
    search: "",
    dataCount: 0,
    options: {},
    currentPage: 1,
    timeoutFilter: null
  }),
  components: {
    ContentWrapper,
    FormPage,
    ActionButtons
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.getData();
        }
      },
      deep: true
    },
    search: {
      handler: function (newVal, oldVal) {
        if (this.timeoutFilter) {
          clearTimeout(this.timeoutFilter);
        }
        if (newVal !== oldVal) {
          let self = this;
          this.timeoutFilter = setTimeout(() => {
            if (self.currentPage !== 1) {
              self.currentPage = 1;
            } else {
              self.getData();
            }
          }, 750);
        }
      }
    }
  },
  mounted() {
    // this.getData();
  },
  methods: {
    moveUp: function (item) {
      item.order--;
      this.store(item);
    },
    moveDown: function (item) {
      item.order++;
      this.store(item);
    },
    store: async function (item) {
      this.isPageLoading = true;
      let url = `/product-categories/${item.id}`;
      let method = "put";
      try {
        let {
          data: { success, message }
        } = await this.$http[method](url, {
          ...item
        });
        if (success) {
          this.$dialog.notify.info("Data berhasil disimpan", {
            position: "top-right",
            timeout: 3000
          });
        } else
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000
          });
      } catch (error) {
        // console.log("err", error);
      } finally {
        this.isPageLoading = false;
        this.getData();
      }
    },
    showForm: async function (isCreate, selectedData = {}) {
      this.selectedData = { ...selectedData };
      this.$nextTick().then(() => {
        this.formTitle = isCreate
          ? "Tambah Kategori Barang"
          : "Ubah Kategori Barang";
        this.isCreate = isCreate;
        this.show = true;
      });
    },
    onClose: function () {
      this.show = false;
    },
    getData: async function () {
      this.isPageLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const sort = `sort=${sortBy[0] || "order"}&sort_type=${
        sortDesc[0] ? "asc" : "asc"
      }`;
      let search = this.search ? this.search.trim() : "";
      try {
        let response = await this.$http.get(
          `/product-categories?per_page=${itemsPerPage}&page=${page}&search=${search}&${sort}`
        );
        let {
          data: { success, data }
        } = response;
        if (success) {
          this.items = [...data.data];
          this.dataCount = data.total;
        } else {
          this.items = [];
          this.dataCount = 0;
        }
      } catch (error) {
        // console.log(error);
        this.items = [];
        this.dataCount = 0;
      } finally {
        this.isPageLoading = false;
      }
    },
    deleteData: async function (item) {
      const res = await this.$alert.delete(this);
      if (res) {
        let {
          data: { success }
        } = await this.$http.delete(`/product-categories/${item.id}`);
        if (success)
          new Promise(() => {
            this.$dialog.notify.info("Data berhasil dihapus", {
              position: "top-right",
              timeout: 3000
            });
            this.getData();
          });
      }
    }
  }
};
</script>
